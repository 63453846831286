import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../lotties/loading-button.json';

const LoadingButtonComponent = ({width, heigth}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div style={styles.menu}>
            <Lottie options={defaultOptions}
                    height={heigth}
                    width={width}
                    isStopped={false}
                    isPaused={false}/>
        </div>
    );
};

const styles = {
    menu: {
        float: 'left'
    }
}

export {LoadingButtonComponent};
