import storageData from "./storageData";

export default {
  setAuthorization: (token, name, admin, id) => {
    storageData.setData('token', token);
    storageData.setData('name', name);
    storageData.setData('id', id);
    storageData.setData('admin', admin);
    window.location.href = '/my-tasks';
  },
  removeAuthorization: () => {
    storageData.removeData('token');
    storageData.removeData('name');
    storageData.removeData('id');
    storageData.removeData('admin');
    window.location.href = '/auth';
  }
}
