import React from 'react';
import {LinearProgress} from "@mui/material";

const LoadingGlobalComponent = ({globalLoading}) => {
    if (globalLoading == true) {
        return (
            <LinearProgress style={{width: '100%', position: 'absolute', top: 0, right: 0}}/>
        )
    }else{
        return (<></>)
    }
};


export {LoadingGlobalComponent};
