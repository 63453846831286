import {gql} from "@apollo/client";

const GET_SPRINTS = gql`
    query getSprints($idProject: String!){
        getSprints(idProject: $idProject) {
            id
            name
            due_date
        }
    }
`;

export default GET_SPRINTS;
