import {gql} from "@apollo/client";

const CREATE_AUTH = gql`
    mutation createAuth($username: String!, $password: String!){
        createToken(input: {
            username: $username
            password: $password
        }){
            token
            admin
            name
            id
        }
    }
`;

export default CREATE_AUTH;
