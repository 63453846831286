import {gql} from "@apollo/client";

const STOP_TASK = gql`
    mutation startHourTask($issue_id: String!){
        stopHourTask(input: {
            issue_id: $issue_id
        })
    }
`;

export default STOP_TASK;
