import {gql} from "@apollo/client";

const GET_ALL_HISTORIES = gql`
    query getAllHistories($projectId: String!) {
       getAllHistory(projectId: $projectId){
        id
        subject
       }
    }
`;

export default GET_ALL_HISTORIES;
