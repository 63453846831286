import React from 'react';
import {Button} from "@mui/material";

const ButtonComponent = (props) => {
    return (
         <Button {...props} variant="contained" fullWidth={true} style={styles.button, {...props.style}} />
    );
};

const styles = {
    button: {
        marginBottom: '10px'
    }
}

export {ButtonComponent};
