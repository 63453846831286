import {gql} from "@apollo/client";

const GET_STATUS_SPRINT = gql`
    query getStatusSprint{
        getStatusSprint{
            id
            name
        }
    }
`;

export default GET_STATUS_SPRINT;
