import React from "react";
import {withRouter} from "../../services/withRouter";
import {MenuComponent} from "../../components/MenuComponent";
import {CardComponent} from "../../components/CardComponent";
import {Box, FormHelperText, Typography} from "@mui/material";
import {Skeleton} from "@mui/lab";
import {Query} from "@apollo/client/react/components";
import GET_ERROR_LOG from "../../querys/queryErrorLog";
import {LogsListComponents} from "../../components/LogsListComponent";

class LogsComponentPure extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <MenuComponent/>
                <header className="content">
                    <CardComponent style={{maxWidth: 1000}}>


                        <Typography variant="h5" component="h6" textAlign={"center"}>
                            Logs de usuários
                        </Typography>

                        <br/>

                        <Box sx={{ width: '100%', typography: 'body1', minWidth: 800 }}>
                            <Query query={GET_ERROR_LOG}>
                                {({data, loading, error}) => {
                                    if (loading) {
                                        return (
                                            <Box sx={{width: '800px'}}>
                                                <Skeleton animation="wave"/>
                                                <Skeleton animation="wave"/>
                                                <Skeleton animation="wave"/>
                                                <Skeleton animation="wave"/>
                                                <Skeleton animation="wave"/>
                                                <Skeleton animation="wave"/>
                                            </Box>
                                        )
                                    } else if (error) {
                                        return (
                                            <>
                                                <FormHelperText error={true} style={{textAlign: "center"}}>
                                                    {error.message}
                                                </FormHelperText>

                                            </>
                                        )
                                    } else if (data) {
                                        return (
                                            <LogsListComponents listData={data.getErrorLog}/>
                                        )
                                    }
                                }}
                            </Query>
                        </Box>

                    </CardComponent>
                </header>
            </>
        );
    }

}


export const LogsComponent = withRouter(LogsComponentPure);
