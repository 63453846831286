import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {ButtonComponent} from "./ButtonComponent";
import {SelectComponent} from "./SelectComponent";
import {TextFieldComponent} from "./TextFieldComponent";
import {useForm} from "react-hook-form";

import moment from "moment";
import {DatePickerComponent} from "./DatePickerComponent";
import {QuerySelect} from "./QuerySelect";
import GET_STATUS_SPRINT from "../querys/getStatusSprint";
import GET_PRIORITIES from "../querys/getPriorities";
import GET_TYPES_TASKS from "../querys/getTypesTasks";
import genti from "../roles/genti.json";
import GET_ALL_HISTORIES from "../querys/queryHistories";
import {Alert, Autocomplete, Skeleton} from "@mui/lab";

function ExpandMoreIcon() {
    return null;
}

function BadgetStatus ({status}) {
    return status == true ?
        (
            <Chip label="Verificado" size="small" style={{
                marginRight: 5
            }} color="success"/>
        ) : (<Chip label="Não verificado" size="small" style={{
            marginRight: 5
        }} color="error"/>)
}



const TaskImportListComponents = (props) => {
    const [cardOpen, setCardOpen] = useState(props['cardOpen'] || false);
    const [statusSprintList, setStatusSprintList] = useState();
    const [idType, setIdType] = useState(null)
    const [hasLoadedType, setHasLoadedType] = useState(false);
    const [queryTypes, setQueryTypes] = useState('');
    const [refetchCommand, setRefetchCommand] = useState(null);
    const [loadingCompleted, setLoadingCompleted] = useState(false);
    const [priotitiesList, setPriotitiesList] = useState();
    const [projectMembershipsList, setProjectMembershipsList] = useState();
    const [customFieldsList, setCustomFieldsList] = useState(props.customFieldsList);
    const [completedId, setCompletedId] = useState(0);
    const [typeTaskList, setTypeTaskList] = useState([]);
    const [historiesList, setHistoriesList] = useState([]);
    const [lastKeyUpdated, setLastKeyUpdated] = useState(null);
    const [customFieldsValues, setCustomFieldsValues] = useState(props.customFieldsValues);
    const [errorData, setErrorData] = useState(false);
    const [enableFields, setEnableFields] = useState({
        module: false,
        env: false,
        plat: false,
        version: false
    });
    const [formErrors, setFormErros] = useState({
        Resumo: false,
        Tipo: true,
        Status: genti['situacao'][props.dataSingle.Status.toLowerCase()] === ('' || null || undefined),
        'Data de Início': props['startSprint'] === "",
        'Data Prevista': errorData
    })
    const [disabledSubmit, setDisabledSubmit] = useState(true)
    const [dataSingle, setDataSingle] = useState({
        Resumo: `${props.dataSingle.ID} ## ${props.dataSingle.Resumo}`,
        Status: genti['situacao'][props.dataSingle.Status.toLowerCase()],
        'Descrição': props.dataSingle['Descrição'],
        'Data de Início': props['startSprint'] != "" ? props['startSprint'] :
            props['startSprint'],
        'Tempo Estimado': props.dataSingle['Estimativa'] ? props.dataSingle['Estimativa'].toString()?.match(/\d+/g).toString() : 0,
        'Terminado': genti['situacao'][props.dataSingle.Status.toLowerCase()] ===  'Concluída' ? 100 : 0,
        'Referência': '',
        'Nível de complexidade': '',
        'Tipo': null,
        'Historia': null,
        'Data Prevista': props['endSprint'] != "" && props['endSprint'] != null ? props['endSprint'] :
            moment().add(15, 'days').format('YYYY-MM-DD')
    });
    const {register, handleSubmit, control, errors} = useForm();

    const setData = (event, key) => {
        let data = event?.target?.value ? event?.target?.value : event;
        if (typeof data !== 'string') {
            data = '';
        }
        let objectChanged = {[key]: data}
        if (key === 'Status' && data === completedId){
            objectChanged['Terminado'] = 100;
        }
        setDataSingle({...dataSingle, ...objectChanged});
        setLastKeyUpdated(key);
    }

    const changeRequired = (key, value) => {
        let data = value?.target?.value ? value?.target?.value : value;
        if (typeof data !== 'string') {
            data = '';
        }
        const empty = data.length === 0 ? true : false;
        setFormErros({...formErrors, [key]: empty})
    }

    useEffect(() => {
        checkStatusFields(lastKeyUpdated)
        setTimeout(() => {
            setLoadingCompleted(true);
        }, 300  * props.exponentialCount)

        const anyError = Object.values(formErrors).some(error => error)
        setDisabledSubmit(anyError);
    }, [projectMembershipsList, props, lastKeyUpdated, dataSingle, formErrors])

    useEffect(() => {
        if(props['cardOpen'] == false) setCardOpen(false)
    }, [props])

    const setCustomFields = (event, key) => {
        const data = event?.target?.value || event;
        const tempDataSingle = dataSingle;
        tempDataSingle.custom = dataSingle?.custom ? dataSingle.custom : [];
        tempDataSingle.custom[key.id] = {
            id: key.id,
            name: key.name,
            value: data
        };
        setDataSingle(tempDataSingle);
    }

    const setStatus = (data) => {
        setDataSingle({...dataSingle, checked: true})
        props.checkedList(data)
    }

    const checkStatusFields = (key) => {
        if (key === 'Tipo') {
            let checked = false;
            typeTaskList?.map(type => {
                if(type.id == dataSingle['Tipo']) {
                    switch (type.name) {
                        case 'Desenvolvimento':
                            setEnableFields({
                                env: true,
                                module: true,
                                plat: true,
                                version: true
                            });
                            break
                        case 'Teste':
                            setEnableFields({
                                env: false,
                                module: true,
                                plat: false,
                                version: true
                            });
                            break
                        case 'Implantação':
                            setEnableFields({
                                env: true,
                                module: true,
                                plat: true,
                                version: true
                            });
                            break
                        default:
                            setEnableFields({
                                env: false,
                                module: false,
                                plat: false,
                                version: false
                            });
                            break
                    }
                }
            })
        }
    }

    return (
        <>
            {loadingCompleted === true ?
                (<div style={styles.accordion}>
                    <form onSubmit={handleSubmit(() =>
                        setStatus(dataSingle))}>
                        <Accordion id={'acord'} expanded={cardOpen}>
                            <Tooltip title={!cardOpen ? 'Clique aqui para expandir' : 'Clique aqui para retrair'}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    onClick={() => setCardOpen(!cardOpen)}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <BadgetStatus status={dataSingle.checked} key={dataSingle.ID} />
                                    <Typography>{dataSingle.Resumo}</Typography>
                                </AccordionSummary>
                            </Tooltip>
                            <AccordionDetails key={'acordDe'}>
                                <Alert severity={'warning'}>Todos os campos em vermelho são obrigatórios</Alert>
                                <br/>

                                <Typography>
                                    <TextFieldComponent multiline rows={4}
                                                        onChange={(event) => {
                                                            setData(event, 'Resumo')
                                                            changeRequired('Resumo', event)
                                                        }}
                                                        error={formErrors['Resumo']}
                                                        helperText={formErrors['Resumo'] ? 'Campo Obrigatório': ''}
                                                        name='title' label="Título">
                                        {dataSingle.Resumo}
                                    </TextFieldComponent>
                                    {errors?.title && <span>Campo Obrigatório</span>}

                                    <QuerySelect query={GET_TYPES_TASKS}
                                                 variables={{query: queryTypes}}
                                                 setQueryData={(data, refetch) => {
                                                     if(hasLoadedType == false) {
                                                         setRefetchCommand(() => refetch)
                                                         setHasLoadedType(true)
                                                     }
                                                     setTypeTaskList(data.getTypesTasks)
                                                 }}>

                                        <Autocomplete
                                            id="typeTask"
                                            onChange={(event, value) => {
                                                setIdType(value)
                                                if (value) {
                                                    setData(value.id, 'Tipo')
                                                    changeRequired('Tipo', value.name)
                                                } else {
                                                    setData(null, 'Tipo')
                                                    changeRequired('Tipo', '')
                                                }
                                            }}
                                            onReset={() => {
                                                setData('', 'Tipo')
                                                changeRequired('Tipo', '')
                                            }}
                                            value={idType}
                                            noOptionsText={'Não foi localizado'}
                                            loading={hasLoadedType}

                                            fullwidth={true}
                                            loadingText={'Aguarde Carregando'}
                                            options={typeTaskList}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) =>{
                                                return <TextFieldComponent {...params}
                                                                           error={formErrors['Tipo']}
                                                                           helperText={
                                                                               formErrors['Tipo'] ? "Campo Obrigatório" : ""
                                                                           }
                                                                           onClick={async (data) => {
                                                                               if (refetchCommand != null) {
                                                                                   setQueryTypes(data.target?.value || null)
                                                                                   const newTypeTasks = await refetchCommand({
                                                                                       query: data.target?.value
                                                                                   })
                                                                                   setTypeTaskList(newTypeTasks.data.getTypesTasks)
                                                                               }
                                                                           }}
                                                                           onChange={async (data) => {
                                                                               if (refetchCommand != null) {
                                                                                   setQueryTypes(data.target.value)
                                                                                   const newTypeTasks = await refetchCommand({
                                                                                       query: data.target?.value
                                                                                   })
                                                                                   setTypeTaskList(newTypeTasks.data.getTypesTasks)
                                                                               }
                                                                           }} label="Tipo" />
                                            }}
                                            style={{marginBottom: '10px'}}
                                        />
                                    </QuerySelect>

                                    <QuerySelect query={GET_ALL_HISTORIES}
                                                 setQueryData={(data) => setHistoriesList(data)}
                                                 variables={{
                                                     projectId: props.projectId
                                                 }}>
                                        <SelectComponent
                                            fullwidth={true}
                                            title={'História'}
                                            onChange={(event) => setData(event, 'Historia')}
                                            helperText="Incorrect entry."
                                        >
                                            {
                                                historiesList?.getAllHistory?.map(history => {
                                                    return (<MenuItem value={history.id}>{history.subject}</MenuItem>)
                                                })
                                            }
                                        </SelectComponent>
                                    </QuerySelect>

                                    <QuerySelect query={GET_STATUS_SPRINT}
                                                 setQueryData={(data) => setStatusSprintList(data)} variables={{}}>
                                        <SelectComponent
                                            fullwidth={true}
                                            title={'Situação'}
                                            value={dataSingle['Status']}
                                            onChange={(event) => {
                                                setData(event, 'Status')
                                                changeRequired('Status', event)
                                            }}
                                            error={formErrors['Status']}
                                            helperText="Campo Obrigatório"
                                        >
                                            {
                                                statusSprintList?.getStatusSprint?.map(sprint => {
                                                    if (sprint.name === 'Concluída' && completedId === 0) {
                                                        setCompletedId(sprint.id)
                                                    }
                                                    if (sprint.name === dataSingle['Status']) {
                                                        setData(sprint.id, 'Status')
                                                    }
                                                    return (<MenuItem value={sprint.id}>{sprint.name}</MenuItem>)
                                                })
                                            }
                                        </SelectComponent>
                                    </QuerySelect>

                                    <QuerySelect query={GET_PRIORITIES}
                                                 setQueryData={(data) => setPriotitiesList(data)} variables={{}}>
                                        <SelectComponent
                                            fullwidth={true}
                                            title={'Prioridade'}
                                            onChange={(event) => setData(event, 'Prioridade')}
                                            helperText="Incorrect entry."
                                        >
                                            {
                                                priotitiesList?.getPriorities?.map(priority =>
                                                    (<MenuItem value={priority.id}>{priority.name}</MenuItem>)
                                                )
                                            }
                                        </SelectComponent>
                                    </QuerySelect>

                                    <DatePickerComponent
                                        onChange={(event) => {
                                            const date = moment(event).format('YYYY-MM-DD');
                                            setData(date, 'Data de Início')
                                            changeRequired('Data de Início', date)
                                        }}
                                        name={'startAt'}
                                        helperText="Campo Obrigatório"
                                        error={formErrors['Data de Início']}
                                        value={dataSingle['Data de Início']} label={'Data de Início'} />

                                    <Alert severity={'warning'}>A data de previsão abaixo é uma sugestão, recomenda-se
                                        que verifique se o valor está correto</Alert>
                                    {
                                        errorData ? (
                                            <Alert severity={'warning'}>A data prevista deve ser maior ou igual a data
                                            de início</Alert>
                                        ) : (<></>)
                                    }
                                    <DatePickerComponent
                                        onChange={(event) => {
                                            const date = moment(event).format('YYYY-MM-DD');
                                            if (date < dataSingle['Data de Início']) {
                                                setErrorData(true)
                                                changeRequired('Data Prevista', '')
                                            } else {
                                                setErrorData(false)
                                                changeRequired('Data Prevista', date)
                                            }
                                            setData(date, 'Data Prevista')
                                        }}
                                        name={'finisedAt'} value={dataSingle['Data Prevista']}
                                        label={'Data Prevista'} />


                                    <TextFieldComponent multiline rows={4}
                                                        onChange={(event) => {
                                                            setData(event, 'Descrição')
                                                        }}
                                                        register={register} name='description' label="Descrição">
                                        { dataSingle['Descrição'] }
                                    </TextFieldComponent>
                                    {
                                        enableFields.version === true ?
                                            (
                                                <TextFieldComponent multiline rows={4}
                                                                    register={register} name='version' label="Versões"
                                                                    onChange={(event) => setCustomFields(event,
                                                                        customFieldsValues.version)}
                                                />
                                            ) : (<></>)
                                    }
                                    {
                                        enableFields.module === true ?
                                            (
                                                <TextFieldComponent multiline rows={4}
                                                                    register={register} name='module' label="Módulo"
                                                                    onChange={(event) => setCustomFields(event,
                                                                        customFieldsValues.module)} />
                                            ) : (<></>)
                                    }
                                    {
                                        enableFields.env === true ?
                                            (

                                                <SelectComponent
                                                    fullwidth={true}
                                                    title={'Ambiente'}
                                                    onChange={(event) => {
                                                        setCustomFields(event, customFieldsValues.env);
                                                    }}
                                                    helperText="Incorrect entry."
                                                >
                                                    {
                                                        customFieldsList?.getCustomFields?.map(customField => {
                                                            if (customField.name == 'Ambiente')
                                                                return customField.possible_values.map(possibleValue => {
                                                                    return (<MenuItem
                                                                        value={possibleValue.value}>
                                                                        {possibleValue.label}</MenuItem>)
                                                                })
                                                        })
                                                    }
                                                </SelectComponent>
                                            ) : (<></>)
                                    }

                                    {
                                        enableFields.plat === true ?
                                            (
                                                <SelectComponent
                                                    fullwidth={true}
                                                    title={'Plataforma'}
                                                    onChange={(event) => {
                                                        setCustomFields(event, customFieldsValues.plat);
                                                    }}
                                                    helperText="Incorrect entry."
                                                >
                                                    {
                                                        customFieldsList?.getCustomFields?.map(customField => {
                                                            if (customField.name == 'Plataforma')
                                                                return customField.possible_values.map(possibleValue => {
                                                                    return (<MenuItem
                                                                        value={possibleValue.value}>
                                                                        {possibleValue.label}</MenuItem>)
                                                                })
                                                        })
                                                    }
                                                </SelectComponent>) : (<></>)
                                    }

                                    <TextFieldComponent register={register} name='timeEsmitative' key='timeEsmitative'
                                                        onChange={(event) => setData(event, 'Tempo Estimado')}
                                                        type={'number'} inputProps={{step: "0.1"}} label="Tempo Estimado">
                                        {dataSingle['Tempo Estimado']}
                                    </TextFieldComponent>

                                    <TextFieldComponent register={register} name='finished' key='finished'
                                                        onChange={(event) => setData(event, 'Terminado')}
                                                        value={dataSingle['Terminado']} inputProps={{step: "0.1"}}
                                                        type={'number'} label="% Terminado">
                                        {dataSingle['Terminado']}
                                    </TextFieldComponent>

                                    <TextFieldComponent register={register} name='reference' key='reference'
                                                        onChange={(event) => setCustomFields(event, customFieldsValues.reference)}
                                                        label="Referência">
                                        {dataSingle['Referência']}
                                    </TextFieldComponent>

                                    <TextFieldComponent register={register} name='levelComplex' key='levelComplex'
                                                        onChange={(event) => setCustomFields(event, customFieldsValues.complex)}
                                                        label="Nível de complexidade">
                                        {dataSingle['Nível de complexidade']}
                                    </TextFieldComponent>


                                    <ButtonComponent type={'submit'} disabled={disabledSubmit}>
                                        Salvar Rascunho</ButtonComponent>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </form>
                </div>) : (<Skeleton animation="wave"/>)}
        </>
    );
};

const styles = {
    accordion: {
        marginBottom: '4px'
    }
}

export {TaskImportListComponents};
