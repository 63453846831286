import React from 'react';
import {Box, FormHelperText} from "@mui/material";
import {Skeleton} from "@mui/lab";
import {Query} from "@apollo/client/react/components";

const QuerySelect = ({children, query, variables, setQueryData, haveLoading}) => {
    return (
        <Query query={query} variables={variables}>
            {({data, loading, error, refetch}) => {
                if (loading) {
                    if (haveLoading)
                        return (
                            <Box sx={{width: '800px'}}>
                                <Skeleton animation="wave"/>
                            </Box>
                        )
                    return (<>
                        {children}
                    </>)
                } else if (error) {
                    return (
                        <FormHelperText error={true} style={{textAlign: "center"}}>
                            {error.message}
                        </FormHelperText>
                    )
                } else if (data) {
                    setQueryData(data, refetch);
                    return children

                }
            }}
        </Query>
    );
};

const styles = {
    card: {
        padding: '30px',
        margin: '30px'
    }
}

export {QuerySelect};
