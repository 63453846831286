import React from 'react';
import {TextField} from "@mui/material";
import {Controller} from "react-hook-form";

const TextFieldComponent = (props) => {

    const register = props?.origin === 'login' ? props.register(props.name) : props?.register;

    return (
        <TextField
            id="outlined-basic"
            {...props}
            {...register}
            fullWidth={true}
            style={styles.input}
            defaultValue={props?.children}
        />
    );
};

const styles = {
    input: {
        marginBottom: '10px'
    }
}

export {TextFieldComponent};
