import React from 'react';
import {Link} from "react-router-dom";
import {Link as LinkStyle} from "@mui/material";

const LinkCustomComponent = (props) => {
    return (
        <LinkStyle style={styles.link}>
            <Link {...props} variant="contained" style={styles.link}/>
        </LinkStyle>
    );
};

const styles = {
    link: {
        textDecoration: "none",
        color: "initial"
    }
}

export {LinkCustomComponent};
