import React from "react";
import {withRouter} from "../../services/withRouter";
import {Box, FormHelperText} from "@mui/material";
import {Query} from "@apollo/client/react/components";
import GET_ISSUES from "../../querys/queryTasks";
import {Skeleton} from "@mui/lab";
import {PendentTaskListComponents} from "../../components/PendentTaskListComponent";

class ShowTasksComponentPure extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Query query={GET_ISSUES}>
                    {({data, loading, error}) => {
                        if (loading) {
                            return (
                                <Box sx={{width: '800px'}}>
                                    <Skeleton animation="wave"/>
                                    <Skeleton animation="wave"/>
                                    <Skeleton animation="wave"/>
                                    <Skeleton animation="wave"/>
                                    <Skeleton animation="wave"/>
                                    <Skeleton animation="wave"/>
                                </Box>
                            )
                        } else if (error) {
                            return (
                                <>
                                    <FormHelperText error={true} style={{textAlign: "center"}}>
                                        {error.message}
                                    </FormHelperText>

                                </>
                            )
                        } else if (data) {
                            const dataFormated = data.getIssues.map(dataIssue => {
                                return {
                                    title: dataIssue.subject,
                                    content: dataIssue.description,
                                    subtitle: dataIssue.project.name,
                                    started: dataIssue.started,
                                    id: dataIssue.id
                                }
                            });

                            return (
                                <PendentTaskListComponents listData={dataFormated}/>
                            )
                        }
                    }}
                </Query>

            </>
        );
    }

}


export const ShowTasksComponent = withRouter(ShowTasksComponentPure);
