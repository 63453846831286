import {gql} from "@apollo/client";

const GET_ERROR_LOG = gql`
    query getErrorLog {
        getErrorLog{
            message
            author
            datetime
            action
        }
    }
`;

export default GET_ERROR_LOG;
