import { ApolloClient, InMemoryCache } from "@apollo/client";
import storageData from "./storageData";

const client = new ApolloClient({
  uri: "https://dev-minhas-horas.coopersystem.com.br/graphql",
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${storageData.getData('token')}`
  }
});

export default client;
