import {gql} from "@apollo/client";

const START_TASK = gql`
    mutation startHourTask($issue_id: String!){
        startHourTask(input: {
            issue_id: $issue_id
        })
    }
`;

export default START_TASK;
