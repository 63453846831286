import {gql} from "@apollo/client";

const GET_CUSTOM_FIELDS = gql`
    query getCustomFields{
        getCustomFields{
            id
            name
            possible_values{
              value
              label
            }
        }
    }
`;

export default GET_CUSTOM_FIELDS;
