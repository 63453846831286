import {gql} from "@apollo/client";

const CREATE_ISSUE = gql`
    mutation createTask(
        $projectId: String!
        $sprintId: String!
        $statusId: String!
        $priorityId: String
        $subject: String!
        $trackerId: String
        $description: String
        $assignedToId: String
        $customFields: [setCustomFields]
        $parentIssueId: String
        $estimatedHours: String
        $doneRatio: String
        $dueDate: String!
        $startDate: String!
    ){
        createIssue(input: {
            project_id: $projectId
            status_id: $statusId
            tracker_id: $trackerId
            priority_id: $priorityId
            subject: $subject
            description: $description
            fixed_version_id:  $sprintId
            assigned_to_id: $assignedToId
            custom_fields: $customFields
            parent_issue_id: $parentIssueId
            estimated_hours: $estimatedHours
            done_ratio: $doneRatio
            due_date: $dueDate
            start_date: $startDate
        }){
            id
        }
    }
`;

export default CREATE_ISSUE;
