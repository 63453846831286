import {gql} from "@apollo/client";

const GET_ISSUES = gql`
    query getTag {
       getIssues {
           id
           started
           subject
           description
           project {
               name
           }
       } 
    }
`;

export default GET_ISSUES;
