import React from "react";
import {withRouter} from "../../services/withRouter";
import {MenuComponent} from "../../components/MenuComponent";
import {CardComponent} from "../../components/CardComponent";
import {Box, Tab, Typography} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {ShowTasksComponent} from "./ShowTasks";
import {ImportTasksComponent} from "./ImportTasks";
import {CecisComponent} from "../../components/CecisComponent";

class TasksComponentPure extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: '1'
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event, newValue) {
        this.setState({tab: newValue})
    }


    render() {
        return (
            <>
                <MenuComponent/>
                <header className="content">
                    <CardComponent style={{maxWidth: 1000}}>

                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={this.state.tab}>
                                <CecisComponent />
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={this.handleChange}>
                                        <Tab label="Tarefas Disponíveis" value="1" />
                                        <Tab label="Importar Tarefas" value="2" />
                                    </TabList>
                                    <TabPanel value="1"><ShowTasksComponent /></TabPanel>
                                    <TabPanel value="2"><ImportTasksComponent /></TabPanel>
                                </Box>

                            </TabContext>
                        </Box>

                    </CardComponent>
                </header>
            </>
        );
    }

}


export const TasksComponent = withRouter(TasksComponentPure);
