import {gql} from "@apollo/client";

const GET_PROJECTS = gql`
    query getProjects($query: String) {
       getProjects(query: $query){
            name
            id
       }
    }
`;

export default GET_PROJECTS;
