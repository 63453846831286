import {gql} from "@apollo/client";

const GET_PRIORITIES = gql`
    query getPriorities{
        getPriorities{
            id
            name
        }
    }
`;

export default GET_PRIORITIES;
