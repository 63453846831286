import React from 'react';
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";

const SelectComponent = (props) => {

    const [value, setValue] = React.useState('');

    const handleChange = (event, onChange) => {
        setValue(event.target?.value);
        onChange(event.target?.value);
    };

    return (
        <div style={styles.input}>
            <FormControl fullWidth>
                <InputLabel id="titlo-label">{props.title}</InputLabel>
                <Select
                    labelId="titlo-label"
                    {...props}
                    label={value}
                    name={'teste'}
                    onChange={(event) => handleChange(event, props?.onChange)}
                >
                    {props.children}
                </Select>

                <FormHelperText> {props.error === true ? props.helperText : '' } </FormHelperText>
            </FormControl>
        </div>

    );
};

const styles = {
    input: {
        marginBottom: '10px',
        width: '100%'
    }
}

export {SelectComponent};
