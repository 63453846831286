export default {
  setData: (name, data) => {
    window.localStorage.setItem(name, data);
  },
  getData: (name) => {
    return window.localStorage.getItem(name);
  },
  removeData: (name) => {
    return window.localStorage.removeItem(name);
  }
}
