import React from 'react';
import {Button} from "@mui/material";

const CecisComponent = (props) => {
    return (
         <div style={styles.cecis}>
             <img src={`./cecis.png`} width={80} style={{float: 'left'}} />
             <b>Olá!</b> <br/>
             Vou te ajudar com suas tarefas do Redmine. <br/>
             <b>Selecione abaixo se deseja iniciar ou importá-las do GENTI.</b>
         </div>
    );
};

const styles = {
    cecis: {
        margin: '20px'
    }
}

export {CecisComponent};
