import './App.css';
import React from "react";

import {BrowserRouter, Link, Route, Routes, Navigate, useParams} from "react-router-dom";
import storageData from "./services/storageData";
import {AuthComponent} from "./screens/Auth/Auth";
import {TasksComponent} from "./screens/tasks/Tasks";
import {LogsComponent} from "./screens/tasks/Logs";

function App() {
    const token = storageData.getData('token');

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={token ? <Navigate to="/my-tasks"/> : <Navigate to="/auth"/>}/>
                <Route path="/auth" element={<AuthComponent/>}/>
                <Route path="/my-tasks" element={<TasksComponent/>}/>
                <Route path="/logs" element={<LogsComponent/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
