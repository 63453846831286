import React from 'react';
import {LogoComponent} from "./LogoComponent";
import {OptionComponent} from "./OptionComponent";
import authService from "../services/authService"
import localStorage from "../services/storageData";
import {Link, useNavigate} from "react-router-dom";
import {Mutation} from "@apollo/client/react/components";
import {Tooltip} from "@mui/material";
import SYNC_DATABASE from "../querys/syncDatabase";
import {LoadingButtonComponent} from "./LoadingButtonComponent";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const checkAdmin = () => {
    return localStorage.getData('admin');
}

const checkId = () => {
    return localStorage.getData('id');
}

const MenuComponent = (props) => {
    let navigate = useNavigate();

    return (
        <div style={styles.menu}>
            <div style={styles.off}>
                {checkAdmin() === "true" ?
                    (
                        <>
                            <Mutation
                                mutation={SYNC_DATABASE}
                            >
                                {(register, {data, loading, error}) => {
                                    if (loading) {
                                        return <LoadingButtonComponent width={100} heigth={50}/>
                                    } else if (error) {
                                        return (
                                            <>
                                                <OptionComponent onClick={() => register()}>
                                                    <Tooltip title={error.message}>
                                                        <ErrorIcon style={{color: 'red'}}/>
                                                    </Tooltip>
                                                    Sincronizar
                                                </OptionComponent>
                                            </>
                                        )
                                    } else if (data) {
                                        return (
                                            <>
                                                <OptionComponent onClick={() => register()}>
                                                    <Tooltip title={'Foi sincronizado com sucesso'}>
                                                        <CheckIcon style={{color: 'green'}}/>
                                                    </Tooltip>
                                                    Sincronizar
                                                </OptionComponent>
                                            </>
                                        )
                                    } else {
                                        return <OptionComponent onClick={() => register()}>Sincronizar</OptionComponent>
                                    }

                                }}
                            </Mutation>
                            <Link to={'/logs'}>
                                <OptionComponent>Logs</OptionComponent>
                            </Link>
                        </>
                    )
                    : (<></>)}
                {checkAdmin() === "true" || checkId() == 105 ?
                    (
                        <Link to={'/logs'}>
                            <OptionComponent>Logs</OptionComponent>
                        </Link>
                    )
                    : (<></>)}
                <OptionComponent onClick={() => authService.removeAuthorization()}>Sair</OptionComponent>
            </div>

            <LogoComponent width={230}/>
        </div>
    );

};

const styles = {
    menu: {
        margin: '10px'
    },
    off: {
        float: 'right',
    }
}

export {MenuComponent};
