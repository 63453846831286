import React from "react";
import {withRouter} from "../../services/withRouter";
import {Box, Divider, Fab, LinearProgress, MenuItem, Snackbar, TextField, Tooltip} from "@mui/material";
import * as Papa from "papaparse";
import {FilePickerComponent} from "../../components/FilePickerComponent";
import {TaskImportListComponents} from "../../components/TaskImportListComponent";
import {ButtonComponent} from "../../components/ButtonComponent";
import GET_PROJECTS from "../../querys/queryProjects";
import {SelectComponent} from "../../components/SelectComponent";
import {QuerySelect} from "../../components/QuerySelect";
import GET_SPRINTS from "../../querys/getSprints";
import CREATE_ISSUE from "../../querys/createTask";
import {Mutation} from "@apollo/client/react/components";
import GET_CUSTOM_FIELDS from "../../querys/getCustomFields";
import {DialogComponent} from "../../components/DialogComponent";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {LoadingGlobalComponent} from "../../components/LoadingGlobalComponent";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {Autocomplete} from "@mui/lab";

class ImportTasksPure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parsedData: "",
            tableRows: [],
            values: [],
            refetch: null,
            queryProject: undefined,
            cardsState: [],
            step: 1,
            checkedList: false,
            openDialog: false,
            openModal: false,
            statusTask: [],
            projectId: null,
            sprintId: null,
            projectList: {},
            sprintList: {},
            hasLoadedProject: false,
            hasLoadedSprint: false,
            errorRequiredField: false,
            lastRequiredFieldError: null,
            tasksConfirmed: [],
            tasksFinished: [],
            startSprint: null,
            endSprint: null,
            limitImport: 20,
            globalLoading: false,
            requiredFieldsOnCSV: [
                'Tipo',
                'ID',
                'Resumo',
                'Status',
                'Prioridade',
                'Severidade',
                'Data de Modificação',
                'Data de Criação',
                'Data de Início',
                'Descrição',
                'Estimativa'
            ],
            excededLimit: false,
            customFieldsList: [],
            customFieldsValues: {
                module: {
                    id: 0,
                    name: null
                },
                env: {
                    id: 0,
                    name: null
                },
                plat: {
                    id: 0,
                    name: null
                },
                version: {
                    id: 0,
                    name: null
                },
                reference: {
                    id: 0,
                    name: null
                },
                complex: {
                    id: 0,
                    name: null
                },
                setted: false
            }
        }
        this.changeHandler = this.changeHandler.bind(this);
    }

    setRefetch = (refetchMethod) => {
        this.setValues(refetchMethod, 'refetch')
    }

    setValues = (data, key) => {
        let dataValues = this.state
        dataValues[key] = data;
        this.setState(dataValues)
    }

    setLoadCustomFields = () => {
        this.setValues(true, 'setted');
        this.state.customFieldsList?.getCustomFields?.map(customField => {
            switch (customField.name) {
                case 'Ambiente':
                    this.setValues({
                        ...this.state.customFieldsValues,
                        env: {
                            id: customField.id,
                            name: customField.name
                        }
                    }, 'customFieldsValues');
                    break;
                case 'Modulo(s)':
                    this.setValues({
                        ...this.state.customFieldsValues,
                        module: {
                            id: customField.id,
                            name: customField.name
                        }
                    }, 'customFieldsValues');
                    break;
                case 'Versão(ôes)':
                    this.setValues({
                        ...this.state.customFieldsValues,
                        version: {
                            id: customField.id,
                            name: customField.name
                        }
                    }, 'customFieldsValues');
                    break;
                case 'Referência':
                    this.setValues({
                        ...this.state.customFieldsValues,
                        reference: {
                            id: customField.id,
                            name: customField.name
                        }
                    }, 'customFieldsValues');
                    break;
                case 'Nível de Complexidade':
                    this.setValues({
                        ...this.state.customFieldsValues,
                        complex: {
                            id: customField.id,
                            name: customField.name
                        }
                    }, 'customFieldsValues');
                    break;
                case 'Plataforma':
                    this.setValues({
                        ...this.state.customFieldsValues,
                        plat: {
                            id: customField.id,
                            name: customField.name
                        }
                    }, 'customFieldsValues');
                    break;
            }
        })

    }

    requestsWithInterval = async (dataList, callback, interval) => {
        await dataList.forEach(async (data, indexData) => {
            await new Promise(async (resolve) =>
                setTimeout(await callback(data, indexData), interval))
        })
    }

    refactoreTasks = (mutation) => {
        this.setValues(true, 'openModal');
        this.requestsWithInterval(this.state.tasksConfirmed, async (task, indexData) => {
            const objectSetted = {
                projectId: this.state.projectId,
                trackerId: task['Tipo'],
                statusId: task['Status'],
                priorityId: task['Prioridade'],
                subject: task['Resumo'],
                description: task['Descrição'],
                sprintId: this.state.sprintId,
                parentIssueId: task['Historia'],
                customFields: task['custom']?.filter(field => field !== undefined),
                estimatedHours: task['Tempo Estimado'] ? task['Tempo Estimado'] : '',
                doneRatio: task['Terminado']?.toString(),
                dueDate: task['Data Prevista'],
                startDate: task['Data de Início']
            }
            try{
                await mutation({
                    variables: objectSetted
                })
                this.setValues({...this.state.tasksFinished, [indexData]: objectSetted}, 'tasksFinished');
                const statusTask = this.state.statusTask;
                statusTask[indexData] = {subject: objectSetted.subject, status: true};
                this.setValues(statusTask, 'statusTask')
            } catch (error) {
                const statusTask = this.state.statusTask;
                statusTask[indexData] = {
                    subject: objectSetted.subject,
                    status: false,
                    messageError: error.message
                };
                this.setValues(statusTask, 'statusTask')
            }
        }, 2000)
    }

    changeHandler = (event) => {
        this.resetState()
        this.setValues(true, 'globalLoading');
        let file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = ((event) => {
            let dataFile = reader.result;
            dataFile = event.target.result.replace(/"([^"]*("[^"]*")[^"])"/g, (match, value) => {
                return '"' + value.replace(/"/g, "'") + '"'
            })
            dataFile = event.target.result.replace(/\t/g, ",")
            Papa.parse(dataFile, {
                header: true,
                skipEmptyLines: true,
                preview: this.state.limitImport + 1,
                dynamicTyping: true,
                complete: function (results) {
                    const rowsArray = [];
                    const valuesArray = [];
                    const firstLine = results.data[0];
                    for (const expected of this.state.requiredFieldsOnCSV) {
                        if (!firstLine.hasOwnProperty(expected)) {
                            this.setValues(true, 'errorRequiredField')
                            this.setValues(expected, 'lastRequiredFieldError')
                            this.setValues(false, 'globalLoading');
                            return ;
                        }
                    }

                    const cardState = this.state.cardsState;
                    results.data.map((data, indexData) => {
                        cardState[indexData] = false;
                        rowsArray.push(Object.keys(data));
                        valuesArray.push(Object.values(data));
                    });

                    this.setValues(cardState, 'cardsState')
                    this.setValues(results.data, 'parsedData')
                    this.setValues(rowsArray[0], 'tableRows')
                    this.setValues(valuesArray, 'values')
                    this.setValues(4, 'step')
                    this.setValues(false, 'globalLoading');
                    if (results.data.length > this.state.limitImport) {
                        this.setValues(true, 'excededLimit');
                    }
                }.bind(this),
            })

        })
        reader.readAsText(file);


    };

    minimizeAllTasks = () => {
        let cardState = this.state.cardsState;
        cardState = cardState.map(() => false)
        this.setValues(cardState, 'cardsState')
    }

    resetState = () => {
        this.setValues("", 'parsedData')
        this.setValues([], 'tableRows')
        this.setValues([], 'values')
        this.setValues([], 'cardsState')
        this.setValues(false, 'checkedList')
    }

    expandCard = () => {

    }

    render() {
        return (
            <>
                <DialogComponent title={'Importação de tarefas'} status={this.state.openModal}
                                 handleClose={() => this.setValues(false, 'openModal')}>
                    {
                        this.state.statusTask.map((task, indexMap) => (<>
                            <div style={{height: 30, display: 'flex', margin: 5}}>
                                {task.status ? (<CheckCircleIcon color={'success'} />) : (<ErrorIcon color={'error'} />)}
                                <b style={{marginLeft: 10}}>{task.subject}</b><br/>
                            </div>
                            {task?.messageError ? (<>Erro: {task.messageError}</>) : (<b></b>)}

                            <Divider />
                        </>))
                    }
                </DialogComponent>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.openDialog}
                    onClose={() => this.setValues(false, 'openDialog')}
                    message="Rascunho de tarefa autorizado"
                    key={'topcenter'}
                />

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.excededLimit}
                    onClose={() => this.setValues(false, 'excededLimit')}
                    message={'Limite de tarefas ultrapassado, limite é: ' + this.state.limitImport}
                    key={'topcenter'}
                />

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.errorRequiredField}
                    onClose={() => this.setValues(false, 'errorRequiredField')}
                    message={'Campo obrigatório não declarado no CSV: ' + this.state.lastRequiredFieldError}
                    key={'topcenter'}
                />

                <LoadingGlobalComponent globalLoading={this.state.globalLoading} />

                <QuerySelect query={GET_PROJECTS}
                             setQueryData={(data, refetch) => {
                                 if (!this.state.hasLoadedProject) {
                                     this.setRefetch(refetch)
                                     this.setValues(data, 'projectList')
                                     this.setValues(true, 'hasLoadedProject')
                                 }
                             }} variables={{query: this.state.queryProject}}>

                    <Autocomplete
                        id="selectProjects"
                        onChange={(event, value) => {
                            this.resetState()
                            if (value !== null) {
                                this.setValues(value.id, 'projectId')
                                this.setValues(1, 'step')
                                this.setValues(2, 'step')
                                this.setValues(false, 'hasLoadedSprint')
                            } else {
                                this.setValues(value, 'projectId')
                                this.setValues(0, 'step')
                                this.setValues(0, 'step')
                                this.setValues(false, 'hasLoadedSprint')
                            }
                        }}
                        onReset={() => {
                            this.resetState()
                        }}
                        noOptionsText={'Não foi localizado'}
                        loading={this.state.hasLoadedProject}
                        value={this.props.projectId}
                        fullwidth={true}
                        loadingText={'Aguarde Carregando'}
                        options={this.state.projectList?.getProjects}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) =>{
                            return <TextField {...params}
                                              onClick={async (data) => {
                                                  this.setValues(data.target.value, 'queryProject')
                                                  const newProjects = await this.state.refetch({
                                                      query: data.target.value
                                                  })
                                                  this.setValues(newProjects.data, 'projectList')
                                              }}
                                              onChange={async (data) => {
                                                  this.setValues(data.target.value, 'queryProject')
                                                  const newProjects = await this.state.refetch({
                                                      query: data.target.value
                                                  })
                                                  this.setValues(newProjects.data, 'projectList')
                                              }} label="Meus Projetos" />
                        }}
                        style={{marginBottom: '10px'}}
                    />
                </QuerySelect>

                {
                    this.state.step >= 2 ?
                        (
                            <QuerySelect query={GET_SPRINTS}
                                         setQueryData={(data) => {
                                             if (!this.state.hasLoadedSprint) {
                                                 this.setValues(data, 'sprintList')
                                                 this.setValues(true, 'hasLoadedSprint')
                                             }
                                         }} variables={{idProject: this.state.projectId}}>
                                <SelectComponent
                                    fullwidth={true}
                                    title={'Sprint'}
                                    value={this.props.projectId}
                                    onChange={(data) => {
                                        let dataSplited = data.split(' ** ');
                                        this.setValues(dataSplited[0], 'sprintId')
                                        this.resetState()
                                        this.setValues(3, 'step')
                                        this.setValues(dataSplited[1], 'startSprint')
                                    }}
                                    helperText="Incorrect entry."
                                >
                                    {
                                        this.state.sprintList?.getSprints?.map(sprint =>
                                            (<MenuItem value={`${sprint.id} ** ${sprint.due_date}`}>
                                                {sprint.name}</MenuItem>)
                                        )
                                    }
                                </SelectComponent>
                            </QuerySelect>
                        ) : (<></>)
                }
                {
                    this.state.step >= 3 ?
                        (<FilePickerComponent
                            fileTypes={'.csv'}
                            error
                            action={this.changeHandler}
                            helperText="Incorrect entry."
                            title={'Abaixo anexe o arquivo no formato CSV'}
                        />) : (<></>)
                }
                <QuerySelect query={GET_CUSTOM_FIELDS}
                             setQueryData={async (data) => {
                                 if (this.state.customFieldsList.length === 0) {
                                     this.setValues(data, 'customFieldsList')
                                     this.setLoadCustomFields()
                                 }
                             }}
                             variables={{}}>
                    {
                        this.state.parsedData !== "" && this.state.step >= 3 ? this.state.parsedData.map((dataSingle, indexDataSingle) => {
                                return <TaskImportListComponents key={`importcsv${indexDataSingle}`}
                                                                 checkedList={(newData) => {
                                                                     this.setValues(true, 'checkedList')
                                                                     this.setValues(true, 'openDialog')
                                                                     const data = this.state.tasksConfirmed;
                                                                     data[indexDataSingle] = newData;
                                                                     this.setValues(data, 'tasksConfirmed')
                                                                     const statusTask = data.map(task => {
                                                                         return {
                                                                             title: task.subject,
                                                                             status: false
                                                                         }
                                                                     })
                                                                     this.setValues(statusTask, 'statusTask')
                                                                 }} dataSingle={dataSingle}
                                                                 startSprint={this.state.startSprint}
                                                                 cardOpen={this.state.cardsState[indexDataSingle]}
                                                                 customFieldsList={this.state.customFieldsList}
                                                                 key={`task${indexDataSingle}`}
                                                                 endSprint={this.state.endSprint}
                                                                 customFieldsValues={this.state.customFieldsValues}
                                                                 projectId={this.state.projectId}
                                                                 exponentialCount={indexDataSingle} />
                            })
                            : (<></>)}
                </QuerySelect>

                <Mutation mutation={CREATE_ISSUE} >
                    {(createTask, {data, loading, error}) => (
                        <>
                            <ButtonComponent onClick={ () => {
                                this.refactoreTasks(createTask)
                            }}
                                             disabled={this.state.step < 4 ||
                                                 (this.state.step >= 4 && this.state.checkedList === false)}
                                             style={{marginTop: '40px'}}>
                                Cadastrar
                            </ButtonComponent>
                        </>
                    )}
                </Mutation>

                <Tooltip title={'Retrair todas as tarefas'}>
                    <Fab enterDelay color="secondary" aria-label="edit" style={{
                        position: 'fixed',
                        right: '20px',
                        bottom: '20px'
                    }} onClick={() => this.minimizeAllTasks()}>
                        <FullscreenExitIcon />
                    </Fab>
                </Tooltip>


            </>);
    }

}


export const ImportTasksComponent = withRouter(ImportTasksPure);
