import {gql} from "@apollo/client";

const GET_TYPES_TASKS = gql`
    query GetTypesTasks($query: String) {
        getTypesTasks(query: $query){
            id
            name
            enabled_standard_fields
        }
    }
`;

export default GET_TYPES_TASKS;
