import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ButtonGroup,
    Chip,
    FormHelperText,
    Typography
} from "@mui/material";
import {ButtonComponent} from "./ButtonComponent";
import {Mutation} from "@apollo/client/react/components";
import {LoadingSend} from "./LoadingSendComponent";
import START_TASK from "../querys/startTask";
import STOP_TASK from "../querys/stopTask";

function ExpandMoreIcon() {
    return null;
}

function setStatus(method, issue_id) {
    return method({
        variables: {
            issue_id
        }
    })
}

function buttonTask(graph, label, id) {
    return (
        <Mutation
            mutation={graph}
        >
            {(register, {data, loading, error}) => {
                if (loading) {
                    return <LoadingSend width={300} heigth={300}/>
                } else if (error) {
                    return (
                        <>
                            <ButtonComponent onClick={() => setStatus(register, id)}>{label}</ButtonComponent>
                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                {error.message}
                            </FormHelperText>
                        </>
                    )
                } else if (data) {
                    return (
                        <>
                            <ButtonComponent onClick={() => setStatus(register, id)}>{label}</ButtonComponent>
                            Registrado com sucesso!
                        </>
                    )
                } else {
                    return <ButtonComponent onClick={() => setStatus(register, id)}>{label}</ButtonComponent>
                }

            }}
        </Mutation>
    )
}


const PendentTaskListComponents = (props) => {
    return (
        <div>
            {props.listData.map(dataSingle =>
                (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            {
                                dataSingle.started ?
                                    (
                                        <Chip label="Iniciada" size="small" style={{
                                            marginRight: 5
                                        }} color="success"/>
                                    ) : (<></>)

                            }
                            <Typography>{dataSingle.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <Chip label={dataSingle.subtitle}/>
                                <br/>
                                {dataSingle.content}
                                <br/>
                                <br/>

                                <ButtonGroup style={{width: '100%'}}>
                                    {buttonTask(START_TASK, 'Iniciar', dataSingle.id)}
                                    {buttonTask(STOP_TASK, 'Finalizar', dataSingle.id)}
                                </ButtonGroup>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            )}

        </div>
    );
};

const styles = {
    card: {
        padding: '30px',
        margin: '30px'
    }
}

export {PendentTaskListComponents};
