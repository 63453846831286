import React from 'react';
import {FormControl} from "@mui/material";

const FilePickerComponent = (props) => {
    return (
        <>
            <FormControl fullWidth style={styles.component}>
                {props.title}
                <input
                    type="file"
                    name="file"
                    accept={props.fileTypes}
                    onChange={props.action}
                    style={{ display: "block", margin: "10px auto" }}
                />
            </FormControl>
        </>

    );
};

const styles = {
    input: {
        margin: '10px'
    },
    component: {
        margin: '20px'
    }
}

export {FilePickerComponent};
