import React from 'react';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from '@mui/x-date-pickers/locales';
import {FormHelperText, TextField} from "@mui/material";

const DatePickerComponent = ({value, label, onChange, error, helperText}) => {
    const portugueseLocale = ptBR.components.MuiLocalizationProvider.defaultProps.localeText;

    return (
        <div style={styles.date}>
            <LocalizationProvider localeText={portugueseLocale} dateAdapter={AdapterMoment}>
                <DatePicker label={label}
                            format={'DD/MM/YYYY'}
                            onChange={onChange}
                            slotProps={{ textField: { fullWidth: true } }}
                value={moment(value)} />
            </LocalizationProvider>
            <FormHelperText> {error === true ? helperText : '' } </FormHelperText>
        </div>
    );
};

const styles = {
    date: {
        marginBottom: '10px',
        width: '100%'
    }
}

export {DatePickerComponent};
